import ApiService from '../../services/ApiService';

const checkRoomWithUser = async (id: any, email: string) => {
    await ApiService.customAxios().post('/user/check', {'key':id, 'email': email})
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            throw new Error(error);
        });
}

export default {
    checkRoomWithUser
}