import {ref} from "vue";

const clickVideo = ref(false);
const clickMicrophone = ref(false);
const clickShare = ref(false);
const clickChat = ref(false);

const clickChatFun = () => {
    clickChat.value = !clickChat.value
};

export default {
    clickVideo,
    clickMicrophone,
    clickShare,
    clickChat,
    clickChatFun
}