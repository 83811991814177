import {defineStore} from 'pinia';
import {connectToNewUser} from "@/composable/peerjs";
import {socketIO} from "@/services/socketService";
import {useMediaDevicesStore} from '@/stores/mediaDevices';

interface User {
    id: string
    name: string,
    socketId: string,
    mediaStreamId: string,
    microphoneStatus: boolean,
    cameraStatus: boolean,
    isShareScreen: boolean
}

export const useSocket = defineStore('socket', {
    state: () => ({
        messages: [],
        user: '',
        users: [] as User[],
        clickCount: 0,
        loadedMessages: false
    }),
    getters: {
        getMessages(state) {
            return state.messages;
        },
        getUser(state) {
            return state.user;
        },
        getUsers(state) {
            return state.users;
        },
        getClick(state) {
            return state.clickCount;
        }
    },
    actions: {
        userConnected(stream, isShareScreen:boolean|false) {
            socketIO.on("user-connected", ({userId}) => {
                connectToNewUser(userId, stream, isShareScreen);
            });
        },
        bindApp() {
            socketIO.on("users", (arr) => {
                useMediaDevicesStore().setVideoSettings(arr);
                useMediaDevicesStore().changeMicrophoneStatus(arr);
                useMediaDevicesStore().changeCameraStatus(arr);
                this.users = arr;
            })

            socketIO.on("loaded-messages", () => {
                this.loadedMessages = true;
            })

            socketIO.on('remove-user', (socketId) => {
               const findUserIndex = this.users.findIndex((item) => item.socketId == socketId);
               if (findUserIndex > -1) {
                   const findUser = this.users.find((item) => item.socketId == socketId);
                   useMediaDevicesStore().removeVideoByStreamId(findUser?.mediaStreamId);
               }
            });
        },
        microphoneStatus(mediaStreamId) {
            socketIO.emit('microphoneStatus', mediaStreamId)
        },
        cameraStatus(mediaStreamId) {
            socketIO.emit('cameraStatus', mediaStreamId)
        },
        joinRoom(roomId, id, user, mediaStreamId) {
            socketIO.emit("join-room", roomId, id, user, mediaStreamId);
        },
        replaceMediaStreamId(mediaStreamId, replaceMediaStreamId) {
            socketIO.emit('replaceMediaStreamId', mediaStreamId, replaceMediaStreamId);
        },
        sendMessage(message) {
            socketIO.emit('message', message);
        },
        createMessage() {
            socketIO.on("createMessage", (message, userName) => {
                const obj = {};
                obj['message'] = message;
                obj['user'] = userName
                this.messages.push(obj);
            });
        },
        setUserName(user) {
            this.user = user;
        }
    }
})
