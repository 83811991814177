import {Peer} from "peerjs";
import {uuid} from 'vue-uuid';
import {useMediaDevicesStore} from "@/stores/mediaDevices";
import {useSocket} from "@/stores/socket";
import {reactive, ref} from "vue";
import dialog from "@/composable/form/dialog";

const {ruleForm, successForm} = dialog

export const peerObject = reactive({'peer':''})
export const roomId = ref('');
export const userId = ref();
export const mediaStreamId = ref();
export const shareScreen = ref();
export let peerCall = reactive();

export const setPeer = () => {
    peerObject.peer = new Peer(uuid.v1(), {
        path: '/peerjs',
        host: process.env.VUE_APP_BACKEND_HOSTNAME,
        port: process.env.VUE_APP_BACKEND_PORT,
        debug: 3
    });

    peerObject.peer.on("open", (id) => {
        userId.value = id;
        useSocket().setUserName(ruleForm.name);
        useSocket().joinRoom(roomId.value, id, ruleForm.name, mediaStreamId.value);
    })
}

//answer call
export const callEvent = (stream, isShareScreen:boolean|false):void => {
    peerObject.peer.on("call", (call) => {
        peerCall = call;
        call.answer(stream);
        //`stream` is the MediaStream of the remote peer.
        call.on("stream", (userVideoStream) => {
            useMediaDevicesStore().addVideoStream(userVideoStream, false, isShareScreen);
            useMediaDevicesStore().setVideoSettings(useSocket().getUsers);
        });
    });
}

//call
export const connectToNewUser = (userid, stream, isShareScreen):void => {
    peerCall = peerObject.peer.call(userid, stream, {metadata: {"userid":userId.value}});
    peerCall.on("stream", (userVideoStream) => {
        useMediaDevicesStore().addVideoStream(userVideoStream, false, isShareScreen);
        useMediaDevicesStore().setVideoSettings(useSocket().getUsers);
    });
}