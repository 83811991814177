import {defineStore} from 'pinia';
import {useSocket} from "@/stores/socket";
import dialog from "@/composable/form/dialog";
import {callEvent, mediaStreamId, peerCall, setPeer, shareScreen} from "@/composable/peerjs";
import actions from "@/composable/actions";

const {dialogError} = dialog;
const {clickShare} = actions

export const useMediaDevicesStore = defineStore('mediaDevice', {
    state: () => ({
        videos: [] as any,
        myVideo: ''
    }),
    getters: {
        getVideos(state) {
            return state.videos;
        },
        getMyVideo(state) {
            return state.myVideo;
        }
    },
    actions: {
        addVideoStream(stream, first:boolean|false, isShareScreen:boolean|false) {
            if(first) {
                this.setMyVideoStream(stream);
            }
            const obj = {};
            obj['stream'] = stream;
            obj['name'] = 'name';
            obj['cameraStatus'] = true;
            obj['microphoneStatus'] = true;
            obj['isShareScreen'] = isShareScreen;
            this.videos.push(obj);
            this.videos = [...new Map(this.videos.map(item =>
                [item['stream']['id'], item])).values()];
        },
        setMyVideoStream(stream) {
            this.myVideo = stream;
        },
        setVideoSettings(arr) {
          this.videos.forEach((item) => {
              const find = arr.find((itemArr) => itemArr.mediaStreamId == item.stream.id);
              if (find) {
                  item.name = find.name;
                  item.microphoneStatus = find.microphoneStatus;
              }
          })
        },
        changeMicrophoneStatus(arr) {
            this.videos.forEach((item) => {
                const find = arr.find((itemArr) => itemArr.mediaStreamId == item.stream.id);
                if (find) {
                    item.microphoneStatus = find.microphoneStatus;
                }
            })
        },
        changeCameraStatus(arr) {
            this.videos.forEach((item) => {
                const find = arr.find((itemArr) => itemArr.mediaStreamId == item.stream.id);
                if (find) {
                    item.cameraStatus = find.cameraStatus;
                }
            })
        },
        removeVideoByStreamId(mediaStreamId) {
            const findIndex = this.videos.findIndex((videos) => videos.stream.id == mediaStreamId);
            if (findIndex > -1) {
                this.videos.splice(findIndex, 1);
            }
        },
        changeStreamForPeerCall(stream) {
            if (typeof peerCall !== 'undefined') {
                peerCall.peerConnection.getSenders().forEach((sender) => {
                    if(sender.track.kind === "audio" && stream.getAudioTracks().length > 0){
                        sender.replaceTrack(stream.getAudioTracks()[0]);
                    }
                    if (sender.track.kind === "video" && stream.getVideoTracks().length > 0) {
                        sender.replaceTrack(stream.getVideoTracks()[0]);
                    }
                });
            }
        },
        async addMediaDeviceCamera(constraints) {
            try {
                await navigator.mediaDevices
                    .getUserMedia(constraints)
                    .then(stream => {
                        stream.getTracks().forEach(track => track.stop());
                    })
                await navigator.mediaDevices
                    .getUserMedia(constraints)
                    .then(stream => {
                        mediaStreamId.value = stream.id;
                        setPeer();
                        this.addVideoStream(stream, true);
                        callEvent(stream);
                        useSocket().userConnected(stream);
                    })
            } catch (error) {
                dialogError.value = true;
                throw new Error(error);
            }
        },
        async changeStreamToVideo() {
            const constraints = {
                audio: true,
                video: true
            };

            await navigator.mediaDevices.getUserMedia(constraints)
                .then(stream => {
                    const find = this.videos.find((videos) => videos.stream == this.myVideo);
                    find.stream = stream;
                    useSocket().replaceMediaStreamId(this.getMyVideo.id, stream.id);
                    this.changeStreamForPeerCall(stream);
                    this.setMyVideoStream(stream);
                })
        },
        async changeStreamToShare() {
            const displayMediaOptions = {
                video: {
                    cursor: "always",
                    displaySurface: "monitor"
                },
                audio: true
            };

            await navigator.mediaDevices.getDisplayMedia(displayMediaOptions)
                .then(stream => {
                    stream.getVideoTracks()[0].addEventListener('ended', async () => {
                        await this.changeStreamToVideo();
                        clickShare.value = !clickShare.value;
                        shareScreen.value = false;
                    });
                    const find = this.videos.find((videos) => videos.stream == this.myVideo);
                    find.stream = stream;
                    useSocket().replaceMediaStreamId(this.getMyVideo.id, stream.id);
                    this.changeStreamForPeerCall(stream);
                    this.setMyVideoStream(stream);
                })
        }
    }
})
