import {reactive, ref} from "vue";
import type {FormInstance, FormRules} from "element-plus";
import DialogRuleForm from "@/types/DialogRuleForm";

const ruleFormRef = ref<FormInstance>()
const successForm = ref(false);
const dialogError = ref(false);
const alertError = ref(false);

const ruleForm = reactive<DialogRuleForm>({
    name: '',
    email: ''
});

const rules = reactive<FormRules<DialogRuleForm>>({
    name: [
        {required: true, message: 'Proszę podać nazwę', trigger: 'blur'},
        {min: 3, message: 'Minimum 3 znaki', trigger: 'blur'},
    ],
    email: [
        {required: true, message: 'Proszę podać Email', trigger: 'blur'}
    ]
})

export default {
    ruleFormRef,
    ruleForm,
    rules,
    successForm,
    dialogError,
    alertError
}